<template>
  <div class="d-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
    <div class="ms-2">
      <div style="min-width: 100px" class="d-flex justify-content-between">
        <span
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('transactions_page.previous_page')"
          v-tooltip
        >
          <button
            class="btn bg-body text-primary border"
            @click="prevPage()"
            :disabled="disablePrevBtn"
          >
            <i class="bi bi-arrow-left"></i></button
        ></span>
        <span
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('transactions_page.next_page')"
          v-tooltip
        >
          <button
            class="btn bg-body text-primary border"
            @click="nextPage()"
            :disabled="disableNextBtn"
          >
            <i class="bi bi-arrow-right"></i></button
        ></span>
      </div>
    </div>
  </div>
  <txn-summary-skeleton v-if="loading" />
  <error v-else-if="error" />
  <contracts-summary v-else />
</template>

<script>
import { defineComponent, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import Search from "@/components/Search.vue";
import ContractsSummary from "@/components/summaries/ContractsSummary.vue";
import Error from "@/components/Error.vue";
import TxnSummarySkeleton from "@/components/skeletons/summaries/TxnSummarySkeleton.vue";

export default defineComponent({
  name: "Contracts",
  components: { Search, ContractsSummary, Error, TxnSummarySkeleton },
  setup() {
    const store = useStore();
    store.dispatch("contracts/fetchContracts");
    const nextPage = () => {
      store.dispatch("contracts/fetchNextPage");
    };
    const prevPage = () => {
      store.dispatch("contracts/fetchPrevPage");
    };
    const startStream = () => {
      store.dispatch("contracts/startStreaming");
    };
    const pauseStream = () => {
      store.dispatch("contracts/stopStreaming");
    };
    const disablePrevBtn = computed(
      () => store.getters["contracts/disablePrevBtn"]
    );
    const disableNextBtn = computed(
      () => store.getters["contracts/disableNextBtn"]
    );
    const streaming = computed(() => store.getters["contracts/isStreaming"]);
    const loading = computed(
      () => store.getters["contracts/getLoadingStatus"]
    );
    const error = computed(() => store.getters["contracts/getError"]);
    onUnmounted(pauseStream);
    return {
      nextPage,
      prevPage,
      disablePrevBtn,
      disableNextBtn,
      startStream,
      pauseStream,
      streaming,
      loading,
      error,
    };
  },
});
</script>


    
