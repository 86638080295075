<template>
  <div v-for="(contract, idx) in contracts" :key="idx" class="list-group mb-2">
    <div class="list-group-item">
      <div class="d-flex align-items-center">
        <router-link
          class="flex-shrink-0 bg-primary bg-opacity-25 rounded-3 d-flex"
          style="height: 36px; width: 36px"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('txn_summary.transaction')"
          v-tooltip
          :to="{
            name: 'TxnDetailsRoute',
            params: {
              txn_hash: contract?.create_transaction?.hash,
            },
          }"
        >
          <i
            class="bi bi-journal fs-5 align-self-center mx-auto text-primary"
          ></i>
        </router-link>
        <div class="flex-shrink-0 ms-3">
          <router-link
            :to="{
              name: 'AccountDetailsRoute',
              params: {
                acc_id: contract?.asset_issuer,
              },
            }"
            class="fw-bold text-decoration-none text-reset font-monospace small"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :data-bs-original-title="$t('effect_types.asset_issuer')"
            v-tooltip
            v-middle-ellipsis="{ size: 6, text: contract?.asset_issuer}"
          />

          <p class="small mb-0 font-monospace">
            <router-link
              :to="{
                name: 'ContractDetailsRoute',
                params: {
                  contract_id: contract?.contract_id,
                },
              }"
              class="text-muted text-decoration-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="$t('contracts_summary.contract_id')"
              v-tooltip
              v-middle-ellipsis="{ size: 8, text: contract?.create_transaction?.hash}"
            />
          </p>
        </div>
        <div class="flex-grow-1">
          <div class="row ms-2">
            <div class="col-xxl-2 col-4 d-flex">
              <span
                v-if="!accountLabels.data[contract?.asset_issuer]"
                class="badge bg-primary bg-opacity-25 align-self-center"
              >
                <router-link
                  :to="{
                    name: 'DirectoryUpdateRoute',
                    query: {
                      account: contract?.asset_issuer,
                    },
                  }"
                  class="
                    text-primary text-decoration-none
                    d-xxl-inline-flex
                  "
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="$t('txn_summary.add_label')"
                  v-tooltip
                  ><i class="bi bi-plus-circle"></i
                ></router-link>
              </span>
              <span
                v-else-if="
                  ['Scam', 'Hack'].includes(
                    accountLabels.data[contract?.asset_issuer].label.name
                  )
                "
                class="badge bg-danger bg-opacity-25 align-self-center"
              >
                <router-link
                  :to="{
                    name: 'AccountDetailsRoute',
                    params: {
                      acc_id: contract?.asset_issuer,
                    },
                  }"
                  class="
                    text-danger text-decoration-none
                    d-none d-xxl-inline-flex
                  "
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  :data-bs-original-title="
                    $t('txn_summary.scam_tooltip', {
                      type: accountLabels.data[
                        contract?.asset_issuer
                      ].label.name.toUpperCase(),
                    })
                  "
                  v-tooltip
                  >{{
                    accountLabels.data[
                      contract?.asset_issuer
                    ].label.name.toUpperCase()
                  }}</router-link
                >
              </span>
              <span
                v-else-if="
                  accountLabels.data[contract?.asset_issuer].label.verified
                "
                class="badge bg-primary bg-opacity-25 align-self-center"
              >
                <router-link
                  :to="{
                    name: 'AccountDetailsRoute',
                    params: {
                      acc_id: contract?.asset_issuer,
                    },
                  }"
                  class="
                    text-primary text-decoration-none
                    d-none d-xxl-inline-flex
                  "
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  :data-bs-original-title="$t('txn_summary.label')"
                  v-tooltip
                  >{{
                    accountLabels.data[contract?.asset_issuer].label.name + "&nbsp;"
                  }}</router-link
                ><i
                  class="bi bi-patch-check-fill text-primary"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  :data-bs-original-title="$t('txn_summary.verified_account')"
                  v-tooltip
                >
                </i>
              </span>

              <span
                v-else-if="
                  accountLabels.data[contract.asset_issuer] &&
                  !accountLabels.data[contract.asset_issuer].label.verified
                "
                class="badge bg-white border align-self-center"
              >
                <router-link
                  :to="{
                    name: 'AccountDetailsRoute',
                    params: {
                      acc_id: contract.asset_issuer,
                    },
                  }"
                  class="
                    text-dark text-decoration-none
                    d-none d-xxl-inline-flex
                  "
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  :data-bs-original-title="$t('txn_summary.label')"
                  v-tooltip
                  >{{
                    accountLabels.data[contract.asset_issuer].label.name + "&nbsp;"
                  }}</router-link
                >
              </span>
            </div>

            <div class="col-xxl-2 d-none d-xxl-block">
              <span class="d-block small fw-bold"
                ><span>{{ $t("effect_types.total_invocations") }}</span></span
              >
              <small>{{contract.transactions_count}}</small>
            </div>

            <div class="col-xxl-2 d-none d-xxl-block">
              <span class="d-block small fw-bold"
                ><span>{{ $t("effect_types.token_symbol") }}</span></span
              >
              <small>{{contract.asset_code || $t("contracts_summary.no_init")}}</small>
            </div>

            <div class="col-xxl-2 d-none d-xxl-block">
              <span class="d-block small fw-bold"
                ><span>{{ $t("effect_types.asset_type") }}</span></span
              >
              <small>{{contract?.create_transaction?.host_functions == "create_contract_from_asset" ? $t("effect_types.asset_address") : $t("effect_types.asset_account")}}</small>
            </div>
            <div class="col-xxl-2 d-none d-xxl-block">
              <span class="d-block small text-muted"
                ><span>{{ $t("txn_summary.fee") }}</span></span
              >
              <small
                class="fw-bold font-monospace"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title=""
                :data-bs-original-title="
                  '$ ' + checkTxnValue(contract?.create_transaction?.hash, 'usd_fee')
                "
                v-tooltip
                >{{
                  $t("txn_summary.xlm") +
                  (0.0000001 * contract?.create_transaction?.fee_charged).toFixed(5)
                }}</small
              >
            </div>
            <div class="col-xxl-2 col-6 text-end">
              <b class="d-block small text-muted"
                >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  :data-bs-original-title="formatDate(contract?.create_transaction?.created_at)"
                  v-tooltip
                  >{{ formatDateElapsedTime(contract?.create_transaction?.created_at) }}</span
                ></b
              >
              <small
                class="fw-bold font-monospace"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title=""
                :data-bs-original-title="
                  '$ ' + checkTxnValue(contract?.create_transaction?.hash, 'usd_value')
                "
                v-tooltip
              >
                {{
                  $t("txn_summary.xlm") + checkTxnValue(contract?.create_transaction?.hash, "xlm_value")
                }}</small
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="contracts && contracts.length==0 && !loading">
    <no-content />
  </div>
</template>
<script>

import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { formatDateElapsedTime, formatDate } from "@/utils/date.js";
import NoContent from "@/components/NoContent.vue";

export default defineComponent({
  name: "ContractsSummary",
  components: {NoContent},
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const route = useRoute();

    const contracts = computed(() => {
        return store.getters["contracts/getLatestContracts"];
    });
    const txnValue = computed(() => store.getters["analytics/getTxnValue"]);
    const checkTxnValue = (txnHash, value) => {
      if (txnHash in txnValue.value.data) {
        return txnValue.value.data[txnHash][value].toLocaleString();
      } else if (!txnValue.value.loading && !txnValue.value.error) {
        return 0;
      } else {
        return "-";
      }
    };
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    const loading = computed(() => store.getters["contracts/getLoading"]);

    return {
      store,
      formatDateElapsedTime,
      formatDate,
      contracts,
      t,
      txnValue,
      loading,
      checkTxnValue,
      accountLabels,
    };
  },
});
</script>
<style scoped>
.bi-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}
</style>
